import React, { useState, useEffect } from 'react';

// Material UI
import { styled, Box, FormControlLabel, Checkbox, IconButton, Typography } from '@material-ui/core';
import { Card, CardHeader, CardContent, CardActions } from '@material-ui/core';
import { TableContainer, Table, TableHead, TableBody, TableRow, TableCell } from '@material-ui/core';
import { Menu, MenuItem } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';

// Components
import { useTextInput, useNumberInput, useToggle } from 'hooks/forms';
import { TextField } from 'components/ui/inputs';
import { ActionButton } from 'components/ui/buttons';
import { SubmitButton, CancelButton } from 'components/ui/forms';

// Utility
import { useNotification } from 'context/notification';
import moment from 'moment-timezone';

// API
import * as AuditAPI from 'pages/admin/audit/AuditAPI';
import { formatPrice } from 'utility/numbers';
import ConfirmAction from 'components/popups/ConfirmAction';



export default function AuditIncidentalsCard(props) {
    const { eventId, onUpdate } = props;

    const [loading, setLoading] = useState(false);
    const [formOpen, setFormOpen] = useState(false);

    const [incidentals, setIncidentals] = useState([]);
    const [incidentalTypes, setIncidentalTypes] = useState([]);

    const [type, setType] = useState('default');
    const description = useTextInput();
    const price = useNumberInput();
    const taxEnabled = useToggle();

    const tax = taxEnabled.checked ? (price.value * 0.13).toFixed(2) : 0;
    const total = taxEnabled.checked ? (price.value * 1.13).toFixed(2) : price.value;

    const formValid = (type !== 'default' && description.value.trim() !== '' && price.value.trim() !== '');


    const { createNotification } = useNotification();


    useEffect(() => {
        AuditAPI.getIncidentalTypes()
            .then(types => setIncidentalTypes(types))
            .catch(e => createNotification(String(e)));
        
        AuditAPI.getIncidentalsForEvent(eventId)
            .then(incidentals => setIncidentals(incidentals))
            .catch(e => createNotification(String(e)));
    // eslint-disable-next-line
    }, []);


    const resetIncidentalForm = () => {
        setType('default');
        description.setValue('');
        price.setValue('');
        taxEnabled.setValue(false);
        setFormOpen(false);
    };

    const createIncidental = async () => {
        try {
            setLoading(true);
            
            await AuditAPI.createIncidental(eventId, {
                type: type,
                description: description.value,
                subtotal: Number(price.value),
                tax: Number(tax),
                total: Number(total)
            });

            const updatedIncidentals = await AuditAPI.getIncidentalsForEvent(eventId);

            setIncidentals(updatedIncidentals);
            resetIncidentalForm();
            onUpdate();
            createNotification('Incidental Created');
        } catch(e) {
            createNotification(String(e));
        }

        setLoading(false);
    };

    const deleteIncidental = async (incId) => {
        try {
            setLoading(true);
            
            await AuditAPI.deleteIncidental(incId, eventId);

            const updatedIncidentals = await AuditAPI.getIncidentalsForEvent(eventId);

            setIncidentals(updatedIncidentals);
            onUpdate();
            createNotification('Incidental Deleted');
        } catch(e) {
            createNotification(String(e));
        }

        setLoading(false);
    };

    const collectIncidental = async (incId) => {
        try {
            setLoading(true);
            
            await AuditAPI.collectIncidental(incId, eventId);

            const updatedIncidentals = await AuditAPI.getIncidentalsForEvent(eventId);
            setIncidentals(updatedIncidentals);

            onUpdate();
            createNotification('Incidental Collected');
        } catch(e) {
            createNotification(String(e));
        }

        setLoading(false);
    };


    return (
        <Card>
            <CardHeader title='Incidentals' />
            <CardContent>
                <TableContainer component={Box}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <HeaderCell width={60}>WHEN</HeaderCell>
                                <HeaderCell width={100}>TYPE</HeaderCell>
                                <HeaderCell>DESCRIPTION</HeaderCell>
                                <HeaderCell width={60} align='right'>SUBTOTAL</HeaderCell>
                                <HeaderCell width={74} align='right'>TAX</HeaderCell>
                                <HeaderCell width={60} align='right'>TOTAL</HeaderCell>
                                <HeaderCell width={70} align='right'></HeaderCell>
                                <HeaderCell width={10} align='right'></HeaderCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {incidentals.map(i => (
                                <Incidental
                                    key={i.id}
                                    id={i.id}
                                    type={i.type}
                                    status={i.status}
                                    desc={i.desc}
                                    subtotal={i.subtotal}
                                    tax={i.tax}
                                    total={i.total}
                                    when={i.when}
                                    loading={loading}
                                    onCollect={collectIncidental}
                                    onDelete={deleteIncidental}
                                />
                            ))}
                            {(incidentals.length === 0 && formOpen === false) && (
                                <TableRow>
                                    <TableCell align='center' colSpan={8}>No incidentals found</TableCell>
                                </TableRow>
                            )}
                            {formOpen && (
                                <TableRow>
                                    <TableCell align='right'></TableCell>

                                    <TableCell>
                                        <TextField select size='small' variant='outlined' placeholder='Type' fullWidth value={type} onChange={(e) => setType(e.target.value)}>
                                            <MenuItem value='default' disabled>Type</MenuItem>
                                            {incidentalTypes.map(i => (
                                                <MenuItem key={i.id} value={i.id}>{i.name}</MenuItem>
                                            ))}
                                        </TextField>
                                    </TableCell>

                                    <TableCell>
                                        <TextField size='small' placeholder='Description' {...description.formProps} />
                                    </TableCell>

                                    <TableCell style={{ paddingLeft: 0, paddingRight: 0 }} align='right'>
                                        <TextField size='small' placeholder='Subtotal' {...price.formProps} />
                                    </TableCell>

                                    <TableCell align='right'>
                                        <FormControlLabel
                                            control={<Checkbox {...taxEnabled.formProps} />}
                                            label={taxEnabled.checked ? formatPrice(tax) : 'Tax?'}
                                            style={{ marginRight: 0 }}
                                        />
                                    </TableCell>

                                    <TableCell align='right'>{formatPrice(total)}</TableCell>

                                    <TableCell></TableCell>

                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </CardContent>

            {formOpen ? (
                <CardActions>
                    <CancelButton onClick={resetIncidentalForm} disabled={loading}>Cancel</CancelButton>
                    <SubmitButton onClick={createIncidental} disabled={formValid === false || loading}>Save</SubmitButton>
                </CardActions>
            ) : (
                <CardActions>
                    <ActionButton disabled={loading} onClick={() => setFormOpen(true)}>New Incidental</ActionButton>
                </CardActions>
            )}
        </Card>
    )
}



function Incidental(props) {
    const { id, type, status, desc, subtotal, tax, total, when, loading } = props;
    const { onCollect, onDelete } = props;

    const [anchor, setAnchor] = useState(null);
    const [confirmDelete, setConfirmDelete] = useState(false);
    const [confirmCollect, setConfirmCollect] = useState(false);

    const handleCollectClick = () => {
        setConfirmCollect(true);
    }

    const handleDeleteClick = () => {
        setConfirmDelete(true);
        setAnchor(null);
    };

    const collectIncidental = () => {
        setConfirmCollect(false);
        onCollect(id);
    };

    const deleteIncidental = () => {
        setConfirmDelete(false);
        onDelete(id);
    };

    return (
        <>
            <TableRow>
                <BodyCell>{moment(when * 1000).format('MMM DD')}</BodyCell>
                <BodyCell>{type}</BodyCell>
                <Description>{desc}</Description>
                <BodyCell align='right'>{formatPrice(subtotal)}</BodyCell>
                <BodyCell align='right'>{formatPrice(tax)}</BodyCell>
                <BodyCell align='right'>{formatPrice(total)}</BodyCell>
                <BodyCell align='center'>
                    {status === 10 && <ActionButton disabled={loading} onClick={handleCollectClick}>Collect</ActionButton>}
                    {status === 20 && <Typography><b>Pending</b></Typography>}
                    {status === 90 && <Typography><b>Paid</b></Typography>}
                </BodyCell>
                <BodyCell align='right' style={{ padding: 0 }}>
                    <IconButton disabled={loading} onClick={e => setAnchor(e.currentTarget)}>
                        <MoreVertIcon />
                    </IconButton>
                </BodyCell>
            </TableRow>

            <Menu
                id={`menu-${id}`}
                anchorEl={anchor}
                keepMounted
                open={Boolean(anchor)}
                onClose={() => setAnchor(null)}
            >
                <MenuItem onClick={handleDeleteClick}>Delete Incidental</MenuItem>
            </Menu>

            <ConfirmAction
                open={confirmCollect}
                onCancel={() => setConfirmCollect(false)}
                onConfirm={collectIncidental}
                confirmText='Collect'
                title={`Collect ${formatPrice(total)}?`}
                description={`This will collect payment for the following: ${desc}`}
            />

            <ConfirmAction
                open={confirmDelete}
                destructive
                onCancel={() => setConfirmDelete(false)}
                onConfirm={deleteIncidental}
                confirmText='Delete'
                title='Delete this incidental?'
                description='This action cannot be undone'
            />
        </>
    )
}



const HeaderCell = styled(TableCell)(({ theme }) => ({
    ...theme.typography.subHeader,
    paddingTop: 0,
    fontWeight: 'bold',
    borderBottom: '0.5px solid'
}));

const BodyCell = styled(TableCell)(({ theme }) => ({
    border: 'none'
}));

const Description = styled(TableCell)(({ theme }) => ({
    border: 'none',
    minWidth: 300
}));